import React, { useEffect, useState } from "react";
import logoWKRadio from "../../images/logo_big.png";
import PlayButton from "./../Tracklist/TracklistPlayButton";
import app from "./../../service/firebase";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

export default function Radio() {
  const [radio, setRadio] = useState({
    id: "",
    isOnline: false,
    currentSong: "",
    currentProgram: "Musica aleatoria",
    currentAuthors: "WK",
    currentCover: "",
    currentProgramId: "",
  });
  const [currentInfo, setCurrentInfo] = useState([]);

  useEffect(() => {
    const db = getFirestore(app);

    onSnapshot(doc(db, "radio", "u2ph09qoquvNJDeNveMy"), (docSnap) => {
      const conf = {
        currentProgramId: docSnap.data().currentProgramId,
        currentProgram: docSnap.data().currentProgram,
        currentSong: docSnap.data().currentProgram,
        isOnline: docSnap.data().currentProgram,
        id: docSnap.data().id,
        currentAuthors: docSnap.data().currentAuthors,
        currentCover: docSnap.data().currentCover,
      };
      setRadio(conf);
      setCurrentInfo([
        {
          authors: [
            {
              name: conf.currentAuthors,
            },
          ],
          duration: "∞",
          id:
            conf.currentProgram === "Musica aleatoria"
              ? "5Ug2KohB2nlqtkmzZ9bwoI"
              : conf.currentProgramId,
          image:
            conf.currentProgram === "Musica aleatoria"
              ? logoWKRadio
              : conf.currentCover,
          isActive: true,
          key: 1,
          type: "radio",
          name: conf.currentProgram,
          preview_url:
            "https://cast4.my-control-panel.com/proxy/institu1/stream",
          saved: false,
          track_number: "01",
          uri: "spotify:track:5Ug2KohB2nlqtkmzZ9bwoI",
        },
      ]);
    });

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <header className="tracklistCustom__header">
      <div
        className="tracklistCustom__cover-art bg-center "
        style={{
          backgroundImage: `url(${
            radio.currentCover === "default" ? logoWKRadio : radio.currentCover
          })`,
        }}
      ></div>

      <div className="tracklistCustom__info">
        <h2 className="tracklistCustom__name">WK Radio</h2>
        <ul className="tracklistCustom__additional-info">
          <li>
            {radio.currentProgram === "Musica aleatoria"
              ? "Reproduciendo ahora"
              : radio.currentProgram}
          </li>
          <li className="tracklistCustom__dot"> • </li>
          <li>
            {radio.currentProgram === "Musica aleatoria"
              ? radio.currentProgram
              : radio.currentAuthors}
          </li>
        </ul>

        <div className="tracklistCustom__buttons">
          <PlayButton
            type="album"
            id="ddssd"
            disabled={false}
            trackList={currentInfo}
          />
        </div>
      </div>
    </header>
  );
}
