import React, { Component } from "react";
import NewReleases from "./NewReleases";
import BlockHeader from "../../components/Common/BlockHeader";
import Chat from "./Chat/Chat";
import Radio from "./Radio";
import "../../styles/CustomTracklist.scss";
import "../../styles/Chat.scss";

export default class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section className="section-separators" style={{ minHeight: "80vh" }}>
        <NewReleases />
        <section className="row">
          <section className="containerRadioInfo">
            <BlockHeader title="Estación principal" description="WK" link="" />
            <div
              className="tracklistCustom__container custom-scrollbar"
              id="tracklist-scroll-parent"
            >
              <Radio />
            </div>
          </section>
          <section className="containerRadioChat">
            <Chat />
          </section>
        </section>
      </section>
    );
  }
}
/**
 authors: [{…}]
duration: "3:12"
id: "5Ug2KohB2nlqtkmzZ9bwoI"
image: "https://i.scdn.co/image/ab67616d00004851703539ff4c2b0af557730281"
isActive: false
key: 1
name: "Quítame la Ropa"
preview_url: "https://p.scdn.co/mp3-preview/7e3f3cc0806f83bd6daa0a455adc85eeaca9b139?cid=951fff5f6dad432e962922e222c86ee3"
saved: true
track_number: "01"
uri: "spotify:track:5Ug2KohB2nlqtkmzZ9bwoI"
 */
