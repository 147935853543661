import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Auth from './Auth/Auth';

import { auth, updateUserInfo, getUserInfo, logout } from './../service/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import logoWKRadio from '../images/logo.png';
import '../styles/Account.scss';

export default function Account() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [user, loading] = useAuthState(auth);
  const [showAuth, setShowAuth] = useState(false);

  useEffect(() => {
    setShowAuth(!user);
    if (user) {
      getUserInfo().then((info) => {
        setPhone(info.phone);
        localStorage.setItem('isGG', info.account === 'admin' ? true : false);
      });
      setName(user.displayName);
      setEmail(user.email);
    }
  }, [user, loading]);

  const handleUpdateIOnfo = () => {
    updateUserInfo(name, phone, email).then((resp) => {
      if (resp) {
        toast.success('Información actualizada correctamente.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
      }
      toast.error(
        'Ocurrio un error al actualizar tu información, intenta de nuevo más tarde.',
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    });
  };

  const handleLogout = () => {
    localStorage.clear();

    logout();
  };

  return (
    <>
      <ToastContainer />
      {showAuth ? (
        <Auth />
      ) : (
        <>
          <section
            className="hero is-success is-fullheight"
            style={{ height: '100vh' }}
          >
            <div className="hero-body">
              <div className="container has-text-centered">
                <div className="column is-4 is-offset-4">
                  <h3 className="title has-text-black">Tu cuenta</h3>
                  <hr className="login-hr" />

                  <div className="card mt-3">
                    <div className="card-content">
                      <figure className="avatar">
                        <img src={logoWKRadio} alt="sas" />
                      </figure>
                      <div className="mt-4">
                        <div className="field">
                          <div className="control">
                            <input
                              value={name}
                              onChange={(ev) => setName(ev.target.value)}
                              className="input is-large"
                              type="text"
                              placeholder="Nombre"
                              autoFocus={true}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <input
                              value={phone}
                              onChange={(ev) => setPhone(ev.target.value)}
                              className="input is-large"
                              type="text"
                              placeholder="Telefono"
                              autoFocus={true}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <input
                              value={email}
                              onChange={(ev) => setEmail(ev.target.value)}
                              className="input is-large"
                              type="email"
                              placeholder="Correo electronico"
                              autoFocus={true}
                            />
                          </div>
                        </div>

                        <button
                          onClick={() => handleUpdateIOnfo()}
                          type="button"
                          className="button is-block is-large is-fullwidth bgBtn"
                        >
                          ACTUALIZAR
                          <i className="fa fa-pen ml-2" aria-hidden="true"></i>
                        </button>

                        <button
                          onClick={() => handleLogout()}
                          type="button"
                          className="button is-danger   is-block is-large is-fullwidth mt-3"
                        >
                          CERRAR SESION
                          <i
                            className="fa fa-door-open ml-2"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
