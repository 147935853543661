import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../service/auth';

const ENDPOINT = 'https://chat.institutowilliams.edu.mx/';
var socket;

export default function Chat(props) {
  const [user, loading] = useAuthState(auth);
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [showChatUI, setShowChatUI] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (user) {
      setUsername(user.displayName);
      setShowChatUI(true);
      handleSocket();
    }

    return () => {
      if (socket !== undefined) {
        socket.close();
      }
    };
  }, [socket, user]);

  const scrollToBottom = () => {
    var element = document.getElementById('mainChatContainer');
    element.scrollTop = element.scrollHeight;
  };

  const handleSendMessage = () => {
    if (message !== '') {
      socket.emit('chatMessage', message);

      setMessage('');
    }
  };

  const handleSocket = () => {
    socket = io(ENDPOINT);
    const params = {
      username: username,
      room: 'radio',
    };
    socket.emit('joinRoom', params, function (err) {
      if (err) {
        this.props.history.push('/');
      }
    });

    socket.on('message', (message) => {
      let containerMessages = document.getElementById('messagesContainer');
      if (message.username !== username) {
        containerMessages.innerHTML += `<li class="clearfix"> <div class="message-data"> <span class="message-data-time">${message.time} - ${message.username}</span> </div><div class="message my-message">${message.text}</div></li>`;
      } else {
        containerMessages.innerHTML += `<li class="clearfix"><div class="message-data text-right"><span class="message-data-time">${message.time}</span></div><div class="message other-message float-right">${message.text}</div></li>`;
      }
      scrollToBottom();
    });
  };

  return (
    <div className="chat">
      {username !== '' && showChatUI ? (
        <>
          <div
            className="chat-header clearfix"
            style={{ backgroundColor: '#003f97' }}
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="chat-about">
                  <h6 className="m-b-0" style={{ color: '#fff' }}>
                    Chat WK Radio
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-history" id="mainChatContainer">
            <ul className="m-b-0" id="messagesContainer"></ul>
          </div>
          <div className="chat-message clearfix">
            <div className="form-group mb-0">
              <input
                type="text"
                className="form-field"
                placeholder="Escribe tu mensaje..."
                value={message}
                onChange={(ev) => setMessage(ev.target.value)}
              />
              <div className="input-group-prepend">
                <button className="btnLike" onClick={() => handleSendMessage()}>
                  <i className="fa fa-send"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="chat-header clearfix"
            style={{ backgroundColor: '#003f97' }}
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="chat-about">
                  <h6 className="m-b-0" style={{ color: '#fff' }}>
                    Chat WK Radio
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-message clearfix">
            <div className="form-group mb-0">
              <input
                id="inputUsername"
                type="text"
                className="form-field"
                placeholder="Escribe tu nombre para unirte al chat..."
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}
              />
              <div className="input-group-prepend">
                <button
                  className="btnLike"
                  onClick={() => {
                    handleSocket();
                    setShowChatUI(true);
                  }}
                >
                  <i className="fa fa-send"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

/*
export default function Chat() {
  const [response, setResponse] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const socket = socketIOClient(ENDPOINT);
  useEffect(() => {
  
    socket.emit("joinRoom", { username: "gg", room: "radio" });
    socket.on("roomUsers", ({ room, users }) => {
      console.log(room);
      console.log(users);
    });
    socket.on("message", (message) => {
      setAllMessages((messages) => [...messages, message]);
      console.log(message);

      let check = allMessages.map((ms) => {
        if (
          message.username === ms.username &&
          message.text === ms.text &&
          message.time === ms.time
        ) {
          return false;
        }
        return true;
      });

      
      if (check) {
     
      }
   

      // Scroll down
    });
  }, [socket]);
  return (
  
  );
}
*/
