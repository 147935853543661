import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  getEpisodesByProgram,
  addEpisode,
  removeEpisode,
} from "../../../service/episode";

export default function Episodes(props) {
  const [showModal, setShowModal] = useState(props.status);
  const [selectedProgram, setSelectedProgram] = useState(props.program);
  const [episodes, setEpisodes] = useState([]);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [authors, setAuthors] = useState("");
  const [duration, setDuration] = useState("");
  const [src, setSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setShowModal(props.status);
    setSelectedProgram(props.program);

    getEpisodesByProgram(props.program.id).then((resp) => {
      setEpisodes(resp);
    });
  }, [props.status, props.program]);

  const handleAddEpisode = () => {
    setIsLoading(true);
    addEpisode(
      name,
      desc,
      authors,
      selectedProgram.image,
      duration,
      selectedProgram.id,
      src
    ).then((resp) => {
      toast.success("Episodio agregado correctamente", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    });
  };

  const handleRemoveEpisode = (uid) => {
    removeEpisode(uid).then((resp) => {
      setEpisodes(episodes.filter((element) => element.id !== uid));
      toast.success("Episodio eliminado correctamente", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  };

  return (
    <div
      id="editProgram"
      className={`modal   ${showModal ? " is-active" : ""}`}
    >
      <div className="modal-background"></div>
      <div className="modal-card  is-fullwidth" style={{ width: "100vh" }}>
        <header className="modal-card-head">
          <p className="modal-card-title">
            Episodios de {selectedProgram.name}
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              setShowModal(!showModal);
            }}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="content">
            <div className="columns">
              <div className="column is-three-fifths">
                <div className="table-container">
                  <table className="table is-bordered is-striped  ">
                    <thead>
                      <tr>
                        <td>Nombre</td>
                        <td>Duración</td>
                        <td>Fecha</td>
                        <td>Acción</td>
                      </tr>
                    </thead>
                    <tbody>
                      {episodes.map((episode) => (
                        <tr key={episode.id}>
                          <td>{episode.name}</td>
                          <td>{episode.duration}</td>
                          <td>{episode.created_at}</td>
                          <td>
                            {" "}
                            <button
                              onClick={() => handleRemoveEpisode(episode.id)}
                              className="button is-danger is-rounded"
                            >
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="column">
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">Agregar episodio</p>
                  </header>
                  <div className="card-content">
                    <div className="content">
                      <div className="field">
                        <label className="label">Nombre</label>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="input is-normal"
                          type="text"
                          placeholder="Ingresa el nombre del programa"
                        />
                      </div>

                      <div className="field">
                        <label className="label">Descripción</label>
                        <input
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                          className="input is-normal"
                          type="text"
                          placeholder="Ingresa una descripción del episodio"
                        />
                      </div>

                      <div className="field">
                        <label className="label">Autores</label>
                        <input
                          value={authors}
                          onChange={(e) => setAuthors(e.target.value)}
                          className="input is-normal"
                          type="text"
                          placeholder="Ingresa los autores del episodio"
                        />
                      </div>

                      <div className="field">
                        <label className="label">Duración (En minutos)</label>
                        <input
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                          className="input is-normal"
                          type="text"
                          placeholder="Ingresa la duración del episodio"
                        />
                      </div>

                      <div className="field">
                        <label className="label">URL del Audio</label>
                        <input
                          value={src}
                          onChange={(e) => setSrc(e.target.value)}
                          className="input is-normal"
                          type="text"
                          placeholder="Ingresa el audio del episodio"
                        />
                      </div>

                      <button
                        onClick={() => handleAddEpisode()}
                        className={`button is-info is-rounded is-fullwidth mt-6 ${
                          isLoading ? "is-loading" : ""
                        }`}
                      >
                        AGREGAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
