import React, { Component } from 'react';
import io from 'socket.io-client';

const ENDPOINT = 'https://chat.institutowilliams.edu.mx/';
var socket;
var socketClientsConnected;
const params = {
  username: 'WK Radio',
  room: 'radio',
};

const paramstClientsConnected = {
  username: 'WK Radio',
  room: 'wmedia',
};
class Chat extends Component {
  state = {
    message: '',
    username: 'WK Radio',
    showChatUI: false,
    usersActives: 0,
  };

  constructor(props) {
    super(props);
    this.handleSendMessage = this.handleSendMessage.bind(this);
  }

  componentWillUnmount() {
    if (socket !== undefined) {
      socket.close();
    }

    if (socketClientsConnected !== undefined) {
      socketClientsConnected.close();
    }
  }

  componentDidMount() {
    socket = io(ENDPOINT);
    socketClientsConnected = io(ENDPOINT);

    socket.emit('joinRoom', params, function (err) {
      if (err) {
        this.props.history.push('/');
      }
    });

    socketClientsConnected.emit(
      'joinRoom',
      paramstClientsConnected,
      function (err) {
        if (err) {
          this.props.history.push('/');
        }
      }
    );

    socketClientsConnected.on('roomUsers', ({ room, users }) => {
      this.setState({
        usersActives: users.length - 1,
      });
    });

    socket.on('message', (message) => {
      let containerMessages = document.getElementById('messagesContainer');
      if (message.username !== this.state.username) {
        containerMessages.innerHTML += `<li class="clearfix"> <div class="message-data"> <span class="message-data-time">${message.time} - ${message.username}</span> </div><div class="message my-message">${message.text}</div></li>`;
      } else {
        containerMessages.innerHTML += `<li class="clearfix"><div class="message-data text-right"><span class="message-data-time">${message.time}</span></div><div class="message other-message float-right">${message.text}</div></li>`;
      }
      this.scrollToBottom();
    });
  }

  scrollToBottom() {
    var element = document.getElementById('mainChatContainer');
    element.scrollTop = element.scrollHeight;
  }

  handleSendMessage() {
    if (this.state.message !== '') {
      socket.emit('chatMessage', this.state.message);

      this.setState({ message: '' });
    }
  }

  render() {
    return (
      <>
        <header className="card-header">
          <p className="card-header-title">Chat </p>
          <div className="card-header-icon" aria-label="more options">
            Usuarios activos: {this.state.usersActives}
          </div>
        </header>
        <div className="chat">
          <div className="chat-header clearfix">
            <div className="row">
              <div className="col-lg-6">
                <div className="chat-about">
                  <h6 className="m-b-0">Chat WK Radio</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-history" id="mainChatContainer">
            <ul className="m-b-0" id="messagesContainer"></ul>
          </div>
          <div className="chat-message clearfix">
            <div className="form-group mb-0">
              <input
                type="text"
                className="form-field"
                placeholder="Escribe tu mensaje..."
                value={this.state.message}
                onChange={(ev) => this.setState({ message: ev.target.value })}
              />
              <div className="input-group-prepend">
                <button className="btnLike" onClick={this.handleSendMessage}>
                  <i className="fa fa-send"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
/*
export default function Chat() {
  const [response, setResponse] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const socket = socketIOClient(ENDPOINT);
  useEffect(() => {
  
    socket.emit("joinRoom", { username: "gg", room: "radio" });
    socket.on("roomUsers", ({ room, users }) => {
      console.log(room);
      console.log(users);
    });
    socket.on("message", (message) => {
      setAllMessages((messages) => [...messages, message]);
      console.log(message);

      let check = allMessages.map((ms) => {
        if (
          message.username === ms.username &&
          message.text === ms.text &&
          message.time === ms.time
        ) {
          return false;
        }
        return true;
      });

      
      if (check) {
     
      }
   

      // Scroll down
    });
  }, [socket]);
  return (
  
  );
}
*/

export default Chat;
