import React, { useEffect, useState } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import PasswordReset from './PasswordReset';
import '../../styles/Auth.scss';

export default function Auth() {
  const [showSignIn, setShowSignIn] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <section
        className="hero is-success is-fullheight mb-6"
        style={{ height: '100vh' }}
      >
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              {showSignIn ? <SignIn /> : <></>}
              {showSignUp ? <SignUp /> : <></>}
              {showPasswordReset ? <PasswordReset /> : <></>}
              <div className="has-text-grey mb-6">
                <p
                  onClick={() => {
                    setShowPasswordReset(false);
                    if (showSignIn) {
                      setShowSignUp(true);
                      setShowSignIn(false);
                    } else {
                      setShowSignUp(false);
                      setShowSignIn(true);
                    }
                  }}
                >
                  {showSignIn ? 'Crear cuenta' : 'Iniciar sesión'}
                </p>
                <p
                  onClick={() => {
                    setShowSignIn(false);
                    setShowSignUp(false);
                    setShowPasswordReset(true);
                  }}
                >
                  ¿Olvidaste tu contraseña?
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
