import React from "react";
import PropTypes from "prop-types";
import { Link, Outlet, useLocation } from "react-router-dom";
import { PROGRAMS } from "../../constants/RouteConstants";
import cd from "../../images/cd.png";

import LazyLoad from "../../components/Common/LazyLoad";
import "../../styles/Block.scss";
import SideAd from "./SideAd";

export default function Block(props, viewType) {
  const location = useLocation();
  return (
    <div className="block">
      <div className="block__media">
        {props.id === "ads" ? (
          <>
            <LazyLoad className="block__img">
              <div
                className={`block__img block__img_root 
                  }`}
              >
                <SideAd />
              </div>
            </LazyLoad>
          </>
        ) : (
          <Link
            to={{
              pathname: `/${"programs"}/${props.id}`,
              state: { modal: true, type: viewType },
              background: window.location,
            }}
            state={{ background: location }}
          >
            <LazyLoad className="block__img">
              <div
                className={`block__img block__img_root bg-${
                  !props.image ? "empty" : "center"
                }`}
                style={{ backgroundImage: `url(${props.image || cd})` }}
              ></div>
            </LazyLoad>
          </Link>
        )}
      </div>
      <div className="block__content">
        {props.id === "ads" ? (
          <></>
        ) : (
          <>
            <Link
              to={{
                pathname: `/${"programs"}/${props.id}`,
                state: { modal: true, type: viewType },
                background: window.location,
              }}
              state={{ background: location }}
            >
              <span className="block__title">{props.name}</span>
            </Link>
            <Outlet />
            <p className="block__subtitle">
              {viewType === "programs" ? (
                <React.Fragment key={props.id}>
                  <Link
                    to={{
                      pathname: `${PROGRAMS}/${props.id}`,
                      state: { modal: true, type: viewType },
                      background: window.location,
                    }}
                    state={{ background: location }}
                  >
                    <span>{props.meta}</span>
                  </Link>
                  <Outlet />
                </React.Fragment>
              ) : (
                props.meta
              )}
            </p>
          </>
        )}
      </div>
    </div>
  );
}
