import React from "react";
import { createRoot } from "react-dom/client";
import AlertManager from "./AlertManager";

class Alert {
  constructor() {
    let alertContainer;
    const existingAlertContainer = document.getElementById("alert");
    if (existingAlertContainer) {
      alertContainer = existingAlertContainer;
    } else {
      const container = document.createElement("div");
      container.id = "alert";
      container.className = "alert__container";
      document.body.appendChild(container);
      alertContainer = container;
    }

    const root = createRoot(alertContainer);
    root.render(<AlertManager show={(fn) => (this.createAlert = fn)} />);
  }

  show = (message) => {
    if (this.createAlert) {
      this.createAlert(message);
    }
  };
}

const alert = new Alert();
export default alert;
