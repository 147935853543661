import React, { useState } from "react";
import { toast } from "react-toastify";

import storage from "../../../service/firebaseStorage";
import { addProgram } from "../../../service/programs";

export default function FormProgram() {
  const [name, setName] = useState("");
  const [meta, setMeta] = useState("");
  const [image, setImage] = useState("");
  const [schedule, setSchedule] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const path = `/programs/${image.name}`;
      const ref = storage.ref(path);
      await ref.put(image);
      const url = await ref.getDownloadURL();

      addProgram(name, meta, url, schedule).then((resp) => {
        if (resp) {
          setName("");
          setMeta("");
          setImage("");
          toast.success("Programa agregado correctamente", {
            position: toast.POSITION.TOP_RIGHT,
          });

          setTimeout(function () {
            window.location.reload();
          }, 500);
        } else {
          toast.error(
            "Ocurrio un error al agregar el programa, intentalo de nuevo más tarde.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
        setIsLoading(false);
      });
    }
  };

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">Agregar programa</p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="field">
            <div className="file has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="resume"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Imagen…</span>
                </span>
                <span className="file-name">{image.name}</span>
              </label>
            </div>
          </div>
          <div className="field">
            <label className="label">Nombre</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input is-normal"
              type="text"
              placeholder="Ingresa el nombre del programa"
            />
          </div>
          <div className="field">
            <label className="label">Autores / Locutores</label>
            <input
              onChange={(e) => setMeta(e.target.value)}
              value={meta}
              className="input is-normal"
              type="text"
              placeholder="Ingresa los autores"
            />
          </div>

          <div className="field">
            <label className="label">Horario</label>
            <input
              onChange={(e) => setSchedule(e.target.value)}
              value={schedule}
              className="input is-normal"
              type="text"
              placeholder="Ingresa el horario de transmisión"
            />
          </div>

          <button
            onClick={() => handleSubmit()}
            className={`button is-info is-rounded is-fullwidth mt-6 ${
              isLoading ? "is-loading" : ""
            }`}
          >
            AGREGAR
          </button>
        </div>
      </div>
    </div>
  );
}
