import app from "./firebase";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  updateDoc,
  setDoc,
  doc,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { async } from "@firebase/util";

export async function getPrograms() {
  const db = getFirestore(app);
  const querySnapshot = await getDocs(collection(db, "programs"));

  let auxArr = [];
  querySnapshot.forEach((doc) => {
    auxArr.push({
      id: doc.id,
      name: doc.data().name,
      image: doc.data().image,
      meta: doc.data().meta,
      date: doc.data().date,
      totalTracks: doc.data().totalTracks,
      schedule: doc.data().schedule,
    });
  });

  return auxArr;
}

export async function getProgramById(id) {
  const db = getFirestore(app);

  const docRef = doc(db, "programs", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());

    const element = {
      pending: false,
      authors: [
        {
          name: docSnap.data().meta,
          id: "12",
        },
      ],
      id: docSnap.id,
      image: docSnap.data().image,
      name: docSnap.data().name,
      date: docSnap.data().date,
      totalTracks: "" + docSnap.data().totalTracks + " episodios",
      isSaved: false,
    };
    //console.log(element);
    return element;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return {};
  }
}

export async function getEpisodesByProgram(id) {
  const db = getFirestore(app);
  const episodes = await getDocs(
    query(collection(db, "episodes"), where("program", "==", id))
  );

  let aux = [];
  episodes.docs.map((mp, index) => {
    aux.push({
      authors: [
        {
          name: mp.data().authors,
          id: mp.data().id,
        },
      ],
      image: mp.data().image,
      track_number: "" + index + "",
      id: mp.data().id,
      name: mp.data().name,
      preview_url: mp.data().src,
      duration: mp.data().duration,
      uri: "spotify:track:" + mp.data().program,
      saved: false,
      desc: mp.data().desc,
      program: mp.data().program,
      src: mp.data().src,
    });
  });

  return aux;
}

export async function addProgram(name, meta, image, schedule) {
  const db = getFirestore(app);
  const current = new Date().getFullYear();
  const id = doc(collection(db, "programs"));

  await setDoc(id, {
    name,
    meta,
    image,
    schedule,
    totalTracks: 0,
    date: current,
    id: id.id,
  });
  return true;
}

export async function updateProgram(
  uid,
  name,
  meta,
  image,
  schedule,
  totalTracks,
  date
) {
  const db = getFirestore(app);
  const conf = doc(db, "programs", uid);
  await updateDoc(conf, {
    name: name,
    meta: meta,
    image: image,
    schedule: schedule,
    totalTracks: totalTracks,
    date: date,
    id: uid,
  }).then(function () {
    return "success";
  });

  return true;
}

export async function removeProgram(uid) {
  const db = getFirestore(app);
  deleteDoc(doc(db, "programs", uid));
  return true;
}

/*
{
    "pending": false,
    "authors": [
        {
            "name": "Cazzu",
            "id": "6w3SkAHYPsQ1bxV7VDlG5y"
        }
    ],
    "id": "6hPsRT5cjxpBOgMfIupL48",
    "image": "https://i.scdn.co/image/ab67616d0000b2735386208df24b2192b61457a1",
    "name": "Nena Trampa",
    "date": "2022",
    "totalTracks": "10 tracks",
    "isSaved": false,
    "items": [
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "01",
            "id": "1K2h9Y50wXiRsENQHLydZO",
            "name": "Jefa",
            "preview_url": "https://p.scdn.co/mp3-preview/cdbcd8ed4996030d45987985dc53efb289c15d7f?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "2:15",
            "uri": "spotify:track:1K2h9Y50wXiRsENQHLydZO",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "02",
            "id": "2wjA6V7u0rLcFehcty11aN",
            "name": "Nena Trampa",
            "preview_url": "https://p.scdn.co/mp3-preview/c29aaa0875a97ea508cb151d29ef2cfc659bd232?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "2:27",
            "uri": "spotify:track:2wjA6V7u0rLcFehcty11aN",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "03",
            "id": "7G9uS8qTQjEnhP5huKomCb",
            "name": "Isla Velde",
            "preview_url": "https://p.scdn.co/mp3-preview/4cf3b4f04542a85e03bd4d5b7a3a0a785c6cc248?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "3:16",
            "uri": "spotify:track:7G9uS8qTQjEnhP5huKomCb",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "04",
            "id": "2dMp9jC7cpEj9wY8FQ8GMa",
            "name": "Yo Yo y Yo",
            "preview_url": "https://p.scdn.co/mp3-preview/a67ab09ff5f01cc9fafc3c020f6569ddec954f54?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "2:53",
            "uri": "spotify:track:2dMp9jC7cpEj9wY8FQ8GMa",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "05",
            "id": "2i4GPmdILmtWx8pESvKME0",
            "name": "Piensame",
            "preview_url": "https://p.scdn.co/mp3-preview/62f1a3c0b830418f9492c419f95c367035f2b03c?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "2:36",
            "uri": "spotify:track:2i4GPmdILmtWx8pESvKME0",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                },
                {
                    "name": "De La Ghetto",
                    "id": "3EiLUeyEcA6fbRPSHkG5kb"
                },
                {
                    "name": "Randy",
                    "id": "7qYeIN2r4H1kBvr0Gm9Iav"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "06",
            "id": "2pEmxGe9nMIjg8wniWkYjJ",
            "name": "Peli-Culeo",
            "preview_url": "https://p.scdn.co/mp3-preview/54aca60cfb07f83ba811efadd6632d7f1d71cccb?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "2:54",
            "uri": "spotify:track:2pEmxGe9nMIjg8wniWkYjJ",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                },
                {
                    "name": "Brray",
                    "id": "1GKIlPFdcewHtpDVCQ8zmJ"
                },
                {
                    "name": "Luar La L",
                    "id": "4axKuDPr6WKcDCyh8vueTY"
                },
                {
                    "name": "Ankhal",
                    "id": "6NmMI7UnfmIpLRYoz8H9jm"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "07",
            "id": "1vuwe8zD8zY4CrNIsUwdQe",
            "name": "Fulete",
            "preview_url": "https://p.scdn.co/mp3-preview/0b3518c198315da3ca5ab216602d3c1cb5f6c32f?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "4:35",
            "uri": "spotify:track:1vuwe8zD8zY4CrNIsUwdQe",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "08",
            "id": "4thRlSvG4x4TWKmvy9n9Fz",
            "name": "La Trampa",
            "preview_url": "https://p.scdn.co/mp3-preview/15112fc4eaa1953516e3c5b6aa51aecf0ce91e83?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "2:59",
            "uri": "spotify:track:4thRlSvG4x4TWKmvy9n9Fz",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                },
                {
                    "name": "Maria Becerra",
                    "id": "1DxLCyH42yaHKGK3cl5bvG"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": "09",
            "id": "0U9OZFbn82oICL2jNbpNHy",
            "name": "Maléfica",
            "preview_url": "https://p.scdn.co/mp3-preview/40dac517e17eeca42cb5a4a1e256c2c4be970a05?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "3:03",
            "uri": "spotify:track:0U9OZFbn82oICL2jNbpNHy",
            "saved": false
        },
        {
            "authors": [
                {
                    "name": "Cazzu",
                    "id": "6w3SkAHYPsQ1bxV7VDlG5y"
                }
            ],
            "image": "https://i.scdn.co/image/ab67616d000048515386208df24b2192b61457a1",
            "track_number": 10,
            "id": "7jzMyl5FmJWNnYsoRn4Sg5",
            "name": "Los Hombres No Lloran",
            "preview_url": "https://p.scdn.co/mp3-preview/11fda0d0ab0bc832a597507eb63b780ddfdc1d28?cid=951fff5f6dad432e962922e222c86ee3",
            "duration": "3:01",
            "uri": "spotify:track:7jzMyl5FmJWNnYsoRn4Sg5",
            "saved": false
        }
    ],
    "isAvailableForPreview": true,
    "error": false
}
*/
