import React, { useState } from 'react';
import { registerWithEmailAndPassword } from './../../service/auth';
import { toast } from 'react-toastify';

import logoWKRadio from '../../images/logo.png';
export default function SignUp() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUp = () => {
    if (!name) {
      toast.error('Debers ingresar tu nombre', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!email) {
      toast.error('Debers ingresar tu correo electronico', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!phone) {
      toast.error('Debers ingresar un número de telefono', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!password) {
      toast.error('Debers ingresar  una contraseña', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    registerWithEmailAndPassword(name, phone, email, password).then((resp) => {
      if (!resp.user.email) {
        toast.error(
          'Ocurrio un error al crear tu cuenta, intentalo de nuevo más tarde.',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    });
  };

  return (
    <>
      <h3 className="title has-text-black">Crear cuenta</h3>
      <hr className="login-hr" />
      <p className="subtitle has-text-black">
        Registrate para tener acceso a todas las funciones de WMedia.
      </p>
      <div className="card mt-3 mb-6">
        <div className="card-content">
          <figure className="avatar">
            <img src={logoWKRadio} alt="sas" />
          </figure>
          <div className="mt-4 ">
            <div className="field">
              <div className="control">
                <input
                  value={name}
                  onChange={(ev) => setName(ev.target.value)}
                  className="input is-large"
                  type="text"
                  placeholder="Nombre"
                  autoFocus={true}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input
                  value={phone}
                  onChange={(ev) => setPhone(ev.target.value)}
                  className="input is-large"
                  type="text"
                  placeholder="Telefono"
                  autoFocus={true}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                  className="input is-large"
                  type="email"
                  placeholder="Correo electronico"
                  autoFocus={true}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input
                  value={password}
                  className="input is-large"
                  type="password"
                  placeholder="Contraseña"
                  onChange={(ev) => setPassword(ev.target.value)}
                />
              </div>
            </div>

            <button
              type="button"
              onClick={() => handleSignUp()}
              className="button is-block is-large is-fullwidth bgBtn"
            >
              CREAR <i className="fa fa-sign-in" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
