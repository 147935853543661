export const TOP_50 = {
  global: "37i9dQZEVXbMDoHDwVN2tF",
  united_states: "37i9dQZEVXbLRQDuF5jeBp",
  italy: "37i9dQZEVXbIQnj7RRhdSX",
  vietnam: "37i9dQZEVXbLdGSmz6xilI",
  united_kingdom: "37i9dQZEVXbLnolsZ8PSNw",
  france: "37i9dQZEVXbIPWwFssbupI",
  sweden: "37i9dQZEVXbLoATJ81JYXz",
  hungary: "37i9dQZEVXbNHwMxAkvmF8",
  germany: "37i9dQZEVXbJiZcmkrIHGU",
  iceland: "37i9dQZEVXbKMzVsSGQ49S",
  taiwan: "37i9dQZEVXbMnZEatlMSiu",
  singapore: "37i9dQZEVXbK4gjvS1FjPY",
  thailand: "37i9dQZEVXbMnz8KIWsvf9",
  belgium: "37i9dQZEVXbJNSeeHswcKB",
  poland: "37i9dQZEVXbN6itCcaL3Tt",
  denmark: "37i9dQZEVXbL3J0k32lWnN",
  austria: "37i9dQZEVXbKNHh6NIXu36",
  indonesia: "37i9dQZEVXbObFQZ3JLcXt",
  greese: "37i9dQZEVXbJqdarpmTJDL",
  finland: "37i9dQZEVXbMxcczTSoGwZ",
  netherlands: "37i9dQZEVXbKCF6dqVpDkS",
  romania: "37i9dQZEVXbNZbJ6TZelCq",
  hong_kong: "37i9dQZEVXbLwpL8TjsxOG",
  estonia: "37i9dQZEVXbLesry2Qw2xS",
  canada: "37i9dQZEVXbKj23U1GF4IR",
  japan: "37i9dQZEVXbKXQ4mDTEBXq",
  malaysia: "37i9dQZEVXbJlfUljuZExa",
  turkey: "37i9dQZEVXbIVYVBNw9D5K",
  india: "37i9dQZEVXbLZ52XmnySJg",
  norway: "37i9dQZEVXbJvfa0Yxg7E7",
  brazil: "37i9dQZEVXbMXbN3EUUhlg",
  costa_rica: "37i9dQZEVXbMZAjGMynsQX",
  argentina: "37i9dQZEVXbMMy2roB9myp",
  south_africa: "37i9dQZEVXbMH2jvi6jvjk",
  spain: "37i9dQZEVXbNFJfN1Vw8d9",
};