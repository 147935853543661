import React, { useEffect, useState } from "react";
import EmptyPage from "../components/Common/Empty";
import BlockHeader from "../components/Common/BlockHeader";
import Tracks from "../components/Common/Tracks";
import { auth } from "../service/auth";
import { ToastContainer } from "react-toastify";
import Auth from "./Auth/Auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFavs } from "../service/favs";
import "../styles/Account.scss";

import { Link } from "react-router-dom";

export default function Favs() {
  const source = { name: "LikedTracks" };
  const [showAuth, setShowAuth] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    if (loading) {
      return;
    }
    setShowAuth(!user);
    getFavs(user.uid).then((resp) => {
      setFavorites(resp);
    });
  }, []);
  const renderEmptyPage = () => {
    return (
      <EmptyPage
        title="Tus episodios favoritas aparecerán aquí"
        button={
          <Link className="empty-page__button flex-center" to={"/"}>
            Inicio
          </Link>
        }
      />
    );
  };

  return (
    <>
      {showAuth ? (
        <Auth />
      ) : (
        <>
          {favorites.length > 0 ? (
            <>
              <ToastContainer />
              <section style={{ minHeight: "80vh" }}>
                <BlockHeader title="Tus Favoritos" />
                <div>
                  <Tracks trackList={favorites} source={source} />
                </div>
              </section>
            </>
          ) : (
            <>{renderEmptyPage()}</>
          )}
        </>
      )}
    </>
  );
}
