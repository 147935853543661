import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chat from "./Chat";
import Programs from "./Program/Programs";
import FormProgram from "./Program/FormProgram";
import "../../styles/Dashboard.scss";
import { getPrograms } from "../../service/programs";
import { getConfigRadio, updateConfigRadio } from "../../service/radio";

export default function Dashboard() {
  const [currentProgramId, setCurrentProgramId] = useState("");
  const [programs, setPrograms] = useState([]);
  const [isRebootServer, setIsRebootServer] = useState(false);
  const [radio, setRadio] = useState({
    id: "",
    isOnline: false,
    currentSong: "",
    currentProgram: "",
    currentAuthors: "",
    currentCover: "",
    currentProgramId: "",
  });

  useEffect(() => {
    getConfigRadio().then((conf) => {
      setRadio(conf);

      setCurrentProgramId(conf.currentProgramId);
    });

    getPrograms().then((items) => {
      setPrograms(items);
    });

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  const handleChangeProgramRadio = (programId) => {
    if (programId === "Musica aleatoria") {
      updateConfigRadio(
        "Musica aleatoria",
        "Musica aleatoria",
        "",
        true,
        "IWK",
        "default"
      ).then((resp) => {
        toast.success("Información actualizada correctamente", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    } else {
      const currentProgram = programs.find(
        (element) => element.id === programId
      );
      updateConfigRadio(
        programId,
        currentProgram.name,
        "",
        true,
        currentProgram.meta,
        currentProgram.image
      ).then((resp) => {
        toast.success("Información actualizada correctamente", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };

  return (
    <section className="section-separators">
      <ToastContainer />
      <section className="columns ">
        <div className="column is-one-third is-half">
          <div className="card ">
            <header className="card-header">
              <p className="card-header-title">Configuración WK Radio</p>
            </header>
            <div className="card-content">
              <div className="content">
                <div className="field">
                  <label className="label">Reproduciendo ahora</label>
                  <div className="control has-icons-left">
                    <div className="select is-large">
                      <select
                        value={currentProgramId}
                        onChange={(ev) => {
                          setCurrentProgramId(ev.target.value);
                          handleChangeProgramRadio(ev.target.value);
                        }}
                      >
                        <option value="Musica aleatoria">
                          Musica aleatoria
                        </option>
                        {programs.map((program) => (
                          <option key={program.id} value={program.id}>
                            {program.name} - {program.meta}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className="icon is-large is-left">
                      <i className="fa-solid fa-radio"></i>
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Servidor</label>
                  <button
                    className={`button is-info is-rounded is-fullwidth ${
                      isRebootServer ? "is-loading" : ""
                    }`}
                    onClick={() => {
                      setIsRebootServer(true);
                      setTimeout(function () {
                        setIsRebootServer(false);
                        toast.success("Servidor reiniciado correctamente", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }, 5000);
                    }}
                  >
                    Reiniciar servidor
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <Chat />
          </div>
        </div>
      </section>
      <section className="columns">
        <div className="column is-two-thirds">
          <Programs programs={programs} />
        </div>

        <div className="column is-one-third">
          <FormProgram />
        </div>
      </section>
    </section>
  );
}
