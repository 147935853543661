import React, { useEffect, useState } from 'react';
import AuthorList from '../../components/Common/AuthorList';
import app from './../../service/firebase';
import logoWKRadio from '../../images/logo_big.png';

import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
export default function PlayerTrackInfo(props) {
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    if (props.trackInfo) {
      if (props.trackInfo.type === 'radio') {
        const db = getFirestore(app);
        onSnapshot(doc(db, 'radio', 'u2ph09qoquvNJDeNveMy'), (docSnap) => {
          const conf = {
            currentProgramId: docSnap.data().currentProgramId,
            currentProgram: docSnap.data().currentProgram,
            currentSong: docSnap.data().currentProgram,
            isOnline: docSnap.data().currentProgram,
            id: docSnap.data().id,
            currentAuthors: docSnap.data().currentAuthors,
            currentCover: docSnap.data().currentCover,
          };
          setAuthors([
            {
              name: conf.currentAuthors,
            },
          ]);

          setName(conf.currentProgram);
        });
      } else {
        setImage(props.trackInfo.image);
        setName(props.trackInfo.name);
      }
    }

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <div className="player__song-info-wrapper">
      {image && <img className="track__cover-art-image" src={image} alt="" />}
      <div className="player__song-info">
        <p className="track__title overflow-ellipsis">{name}</p>
        <p className="track__authors overflow-ellipsis">
          <AuthorList authors={authors} />
        </p>
      </div>
    </div>
  );
}
