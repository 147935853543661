import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { NavLink } from 'react-router-dom';
import { Menu, X } from 'react-feather';

import * as RouteConstant from '../constants/RouteConstants';
import { ReactComponent as Home } from '../images/Navbar/Radio.svg';
import { ReactComponent as User } from '../images/Navbar/User.svg';
import { ReactComponent as Heart } from '../images/Navbar/Heart.svg';
import { ReactComponent as Settings } from '../images/Navbar/Settings.svg';
import { ReactComponent as Tv } from '../images/Navbar/Tv.svg';
import { ReactComponent as Linkedin } from '../images/Navbar/Linkedin.svg';
import logoWK from '../images/wk.png';
import '../styles/Navbar.scss';
import { auth, getUserInfoNav } from '../service/auth';

export default function Navbar() {
  const [accountType, setAccountType] = useState('normal');
  const [user] = useAuthState(auth);
  const [isHidden, setIsHidden] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navbarItems = [
    { path: RouteConstant.HOME, icon: <Home />, name: 'WK Radio' },
    { path: RouteConstant.WKTV, icon: <Tv />, name: 'WK TV' },

    { path: RouteConstant.FAVORITES, icon: <Heart />, name: 'Favoritos' },
    { path: RouteConstant.ACCOUNT, icon: <User />, name: 'Tu Cuenta' },

    {
      path: RouteConstant.DASHBOARD,
      icon: <Settings />,
      name: 'Configuración',
    },
  ];

  useEffect(() => {
    if (user) {
      getUserInfoNav(user.uid).then((info) => {
        setAccountType(info.account);
      });
    }

    // Anything in here is fired on component mount.
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);

    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, [user, accountType]);

  const checkWindowWidth = () => {
    setIsHidden(window.innerWidth <= 1024);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <React.Fragment>
      {isHidden && (
        <div
          className="navbar__open-btn flex-center fixed-btn"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <X /> : <Menu />}
        </div>
      )}
      <nav
        className={`app__navbar ${
          isHidden ? `app__navbar_mobile${isMenuOpen ? '_open' : ''}` : ''
        }`}
      >
        <div className="navbar">
          <div className="navbar__logo">
            <img className="navbar__logo-img" src={logoWK} alt="" />
          </div>
          <ul className="navbar__group">
            {navbarItems.slice(0, 2).map(({ path, icon, name }, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    isMenuOpen && toggleMenu();
                  }}
                >
                  <NavLink
                    to={path}
                    className={(navData) =>
                      navData.isActive ? 'navbar__item active' : 'navbar__item'
                    }
                  >
                    <span className="navbar__icon">{icon}</span>
                    {name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <ul className="navbar__group">
            <p className="navbar__group-header">TU BIBLIOTECA</p>
            {navbarItems.slice(2, 4).map(({ path, icon, name }, index) => {
              return (
                <li
                  key={index + 4}
                  onClick={() => {
                    isMenuOpen && toggleMenu();
                  }}
                >
                  <NavLink
                    to={path}
                    className={(navData) =>
                      navData.isActive ? 'navbar__item active' : 'navbar__item'
                    }
                  >
                    <span className="navbar__icon">{icon}</span>
                    {name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          {user && accountType === 'admin' ? (
            <>
              <ul className="navbar__group">
                <p className="navbar__group-header">DASHBOARD</p>
                {navbarItems.slice(4, 5).map(({ path, icon, name }, index) => {
                  return (
                    <li
                      key={index + 4}
                      onClick={() => {
                        isMenuOpen && toggleMenu();
                      }}
                    >
                      <NavLink
                        to={path}
                        className={(navData) =>
                          navData.isActive
                            ? 'navbar__item active'
                            : 'navbar__item'
                        }
                      >
                        <span className="navbar__icon">{icon}</span>
                        {name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <></>
          )}

          <ul className="navbar__group" style={{ marginTop: '5vh' }}>
            <p className="navbar__group-header">DESARROLLO POR HUGO LUNA</p>
            <li
              onClick={() => {
                isMenuOpen && toggleMenu();
              }}
            >
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/in/hugodariolc/"
                target="_blank"
                className="navbar__item "
              >
                <span className="navbar__icon">
                  <Linkedin />
                </span>
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
}
