import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import UpdateProgram from "./UpdateProgram";
import Episodes from "./Episodes";
import { removeProgram, getPrograms } from "../../../service/programs";

export default function Programs(programs) {
  const [programsAux, setProgramsAux] = useState(programs.programs);
  const [showModalEpisodes, setShowModalEpisodes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState();

  useEffect(() => {
    setProgramsAux(programs.programs);
    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [programs.programs]);
  const handleRemoveProgram = (uid) => {
    removeProgram(uid).then((resp) => {
      toast.success("Programa eliminado correctamente", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setProgramsAux(programsAux.filter((element) => element.id !== uid));
    });
  };
  return (
    <>
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Programas</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="table-container">
              <table className="table is-bordered is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <td>Nombre</td>
                    <td>Autores</td>
                    <td>Episodios</td>
                    <td>Fecha</td>
                    <td>Acciones</td>
                  </tr>
                </thead>
                <tbody>
                  {programsAux.map((program) => (
                    <tr key={program.id}>
                      <td>{program.name}</td>
                      <td>{program.meta}</td>
                      <td>{program.totalTracks}</td>
                      <td>{program.date}</td>
                      <td style={{ textAlign: "center" }}>
                        <button
                          className="button is-info is-rounded mr-2"
                          data-target="#editProgram"
                          aria-haspopup="true"
                          onClick={() => {
                            setShowModal(!showModal);
                            setSelectedProgram(program);
                          }}
                        >
                          Editar
                        </button>
                        <button
                          className="button is-warning is-rounded mr-2"
                          onClick={() => {
                            setShowModalEpisodes(!showModalEpisodes);
                            setSelectedProgram(program);
                          }}
                        >
                          Episodios
                        </button>
                        <button
                          onClick={() => handleRemoveProgram(program.id)}
                          className="button is-danger is-rounded"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <UpdateProgram status={showModal} program={selectedProgram} />
      ) : (
        <></>
      )}
      {showModalEpisodes ? (
        <Episodes status={showModalEpisodes} program={selectedProgram} />
      ) : (
        <></>
      )}
    </>
  );
}
