import React from "react";
import { Link } from "react-router-dom";

import EmptyPage from "../components/Common/Empty";
import { HOME } from "../constants/RouteConstants";

export default function Page404() {
  return (
    <EmptyPage
      title="Parece que no se encontró nada en este lugar"
      button={
        <Link className="empty-page__button flex-center" to={HOME}>
          Volver
        </Link>
      }
    />
  );
}
