import firebase from "firebase/compat/app";
import "firebase/compat/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCScT-kNuO_ogqLBZIeSaUPxSUhlT9qbSg",
  authDomain: "wk-media-338f3.firebaseapp.com",
  projectId: "wk-media-338f3",
  storageBucket: "wk-media-338f3.appspot.com",
  messagingSenderId: "249971734687",
  appId: "1:249971734687:web:2f091b97dbcef7fad44aaa",
  measurementId: "G-BF7EH4CV9L",
};

const storage = firebase.initializeApp(firebaseConfig).storage();

export default storage;
