import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import "../../../styles/Dashboard.scss";
import storage from "../../../service/firebaseStorage";
import { updateProgram } from "../../../service/programs";

export default function UpdateProgram(props) {
  const [showModal, setShowModal] = useState(props.status);
  const [selectedProgram, setSelectedProgram] = useState(props.program);
  const [name, setName] = useState("");
  const [meta, setMeta] = useState("");
  const [configImage, setConfigImage] = useState("no");
  const [image, setImage] = useState("");
  const [schedule, setSchedule] = useState("");
  const [totalTracks, setTotalTracks] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setShowModal(props.status);
    setSelectedProgram(props.program);
    setName(selectedProgram.name);
    setMeta(selectedProgram.meta);
    setImage(selectedProgram.image);
    setSchedule(selectedProgram.schedule);
    setTotalTracks(selectedProgram.totalTracks);
    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [props.status, props.program]);

  const handleUpdateProgram = async () => {
    if (configImage === "no") {
      setIsLoading(true);
      updateProgram(
        selectedProgram.id,
        name,
        meta,
        image,
        schedule,
        totalTracks,
        props.program.date
      ).then((resp) => {
        setIsLoading(false);

        toast.success("Programa editado correctamente", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(function () {
          window.location.reload();
        }, 500);
      });
    } else {
      setIsLoading(true);
      const path = `/programs/${image.name}`;
      const ref = storage.ref(path);
      await ref.put(image);
      const url = await ref.getDownloadURL();
      updateProgram(
        selectedProgram.id,
        name,
        meta,
        url,
        schedule,
        totalTracks,
        props.program.date
      ).then((resp) => {
        if (resp) {
          setImage(url);
          toast.success("Programa editado correctamente", {
            position: toast.POSITION.TOP_RIGHT,
          });

          setTimeout(function () {
            window.location.reload();
          }, 500);
        } else {
          toast.error(
            "Ocurrio un error al editar el programa, intentalo de nuevo más tarde.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
        setIsLoading(false);
      });
    }
  };

  return (
    <div
      id="editProgram"
      className={`modal   ${showModal ? " is-active" : ""}`}
    >
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            Editar programa / {selectedProgram.name}
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              setShowModal(!showModal);
              props.status = !showModal;
            }}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="content">
            <div className="field">
              <label className="label">¿Desea actualizar la imagen?</label>
              <div className="control has-icons-left">
                <div className="select is-large is-fullwidth">
                  <select
                    value={configImage}
                    onChange={(e) => setConfigImage(e.target.value)}
                  >
                    <option value="no">No</option>
                    <option value="si">Sí</option>
                  </select>
                </div>
                <span className="icon is-large is-left">
                  <i className="fa-solid fa-image"></i>
                </span>
              </div>
            </div>

            {configImage === "si" ? (
              <div className="field mt-5">
                <div className="file has-name is-fullwidth">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      name="resume"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload"></i>
                      </span>
                      <span className="file-label">Imagen…</span>
                    </span>
                    <span className="file-name">{image.name}</span>
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="field">
              <label className="label">Nombre</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="input is-normal"
                type="text"
                placeholder="Ingresa el nombre del programa"
              />
            </div>
            <div className="field">
              <label className="label">Autores / Locutores</label>
              <input
                onChange={(e) => setMeta(e.target.value)}
                value={meta}
                className="input is-normal"
                type="text"
                placeholder="Ingresa los autores"
              />
            </div>

            <div className="field">
              <label className="label">Horario</label>
              <input
                onChange={(e) => setSchedule(e.target.value)}
                value={schedule}
                className="input is-normal"
                type="text"
                placeholder="Ingresa el horario de transmisión"
              />
            </div>

            <div className="field">
              <label className="label">Número de episodios</label>
              <input
                onChange={(e) => setTotalTracks(e.target.value)}
                value={totalTracks}
                className="input is-normal"
                type="number"
                placeholder="Ingresa el número de episodios/ capitulos"
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            onClick={() => handleUpdateProgram()}
            className={`button is-info is-rounded is-fullwidth mt-6 ${
              isLoading ? "is-loading" : ""
            }`}
          >
            ACTUALIZAR
          </button>
        </footer>
      </div>
    </div>
  );
}
