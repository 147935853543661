import app from "./firebase";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  addDoc,
  doc,
  query,
  deleteDoc,
  where,
} from "firebase/firestore";
import { async } from "@firebase/util";

export async function getFavs(uuid) {
  const db = getFirestore(app);
  const episodes = await getDocs(
    query(collection(db, "favorites"), where("user", "==", uuid))
  );
  let aux = [];
  episodes.docs.map((mp, index) => {
    console.log(mp.data().desc);
    aux.push({
      authors: [
        {
          name: mp.data().authors,
          id: "" + index + "",
        },
      ],
      image: mp.data().image,
      track_number: "" + index + "",
      id: "",
      name: mp.data().name,
      preview_url: mp.data().src,
      duration: mp.data().duration,
      uri: "spotify:track:" + mp.data().program,
      saved: false,
    });
  });

  return aux;
}

export async function addFav(
  uuid,
  authors,
  craated_at,
  desc,
  duration,
  id,
  image,
  name,
  program,
  src
) {
  const db = getFirestore(app);
  await addDoc(collection(db, "favorites"), {
    user: uuid,
    authors: authors[0].name,
    craated_at,
    desc,
    duration,
    id,
    image,
    name,
    program,
    src,
  });

  return true;
}

export async function removeFav(uuid, fav) {
  const db = getFirestore(app);

  const q = query(collection(db, "favorites"), where("user", "==", uuid));
  const allConditions = query(q, where("id", "==", fav));

  const querySnapshot = await getDocs(allConditions);
  querySnapshot.forEach((d) => {
    deleteDoc(doc(db, "favorites", d.id));
  });

  return true;
}

export async function isFav(uuid, fav) {
  const db = getFirestore(app);

  const q = query(collection(db, "favorites"), where("user", "==", uuid));
  const allConditions = query(q, where("id", "==", fav));

  const querySnapshot = await getDocs(allConditions);
  return querySnapshot.docs.length > 0;
}
