import app from './firebase';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  updateEmail,
  signOut,
} from 'firebase/auth';

import {
  getFirestore,
  getDoc,
  setDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';

const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res;
  } catch (err) {
    return { status: 'error' };
  }
};
const registerWithEmailAndPassword = async (name, phone, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      name,
      phone,
      authProvider: 'local',
      email,
      account: 'normal',
    });
    updateProfile(user, {
      displayName: name,
    });
    return res;
  } catch (err) {
    return { status: 'error' };
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    console.error(err);
  }
};
const logout = () => {
  signOut(auth);
};

const getUserInfoNav = async (uid) => {
  const db = getFirestore(app);

  const docRef = doc(db, 'users', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      account: docSnap.data().account,
      email: docSnap.data().email,
      name: docSnap.data().name,
      phone: docSnap.data().phone,
      uid: docSnap.data().uid,
    };
  } else {
    return {
      account: '',
      email: '',
      name: '',
      phone: '',
      uid: '',
    };
  }
};

const getUserInfo = async () => {
  const db = getFirestore(app);

  const docRef = doc(db, 'users', auth.currentUser.uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      account: docSnap.data().account,
      email: docSnap.data().email,
      name: docSnap.data().name,
      phone: docSnap.data().phone,
      uid: docSnap.data().uid,
    };
  } else {
    return {
      account: '',
      email: '',
      name: '',
      phone: '',
      uid: '',
    };
  }
};

const updateUserInfo = async (name, phone, email) => {
  const db = getFirestore(app);
  const conf = doc(db, 'users', auth.currentUser.uid);

  await updateDoc(conf, {
    name,
    phone,
    email,
  }).then(function () {
    updateProfile(auth.currentUser, {
      displayName: name,
    });
    updateEmail(auth.currentUser, {
      email,
    });
  });
  return true;
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  updateUserInfo,
  getUserInfo,
  getUserInfoNav,
  logout,
};
