import app from "./firebase";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  addDoc,
  doc,
  query,
  where,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { async } from "@firebase/util";

export async function getConfigRadio() {
  const db = getFirestore(app);

  const docRef = doc(db, "radio", "u2ph09qoquvNJDeNveMy");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      currentProgramId: docSnap.data().currentProgramId,
      currentProgram: docSnap.data().currentProgram,
      currentSong: docSnap.data().currentProgram,
      isOnline: docSnap.data().currentProgram,
      id: docSnap.data().id,
      currentAuthors: docSnap.data().currentAuthors,
      currentCover: docSnap.data().currentCover,
    };
  } else {
    return {
      id: "",
      isOnline: false,
      currentSong: "",
      currentProgram: "",
      currentAuthors: "",
      currentCover: "",
      currentProgramId: "",
    };
  }
}

export async function updateConfigRadio(
  currentProgramId,
  currentProgram,
  currentSong,
  isOnline,
  currentAuthors,
  currentCover
) {
  const db = getFirestore(app);
  const conf = doc(db, "radio", "u2ph09qoquvNJDeNveMy");

  await updateDoc(conf, {
    currentProgramId: currentProgramId,
    currentProgram: currentProgram,
    currentSong: currentSong,
    isOnline: isOnline,
    currentAuthors: currentAuthors,
    currentCover: currentCover,
  }).then(function () {
    return "success";
  });

  return true;
}
