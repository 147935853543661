import React, { useEffect } from "react";
//import { Route, Switch, withRouter } from "react-router-dom";
import { Route, Routes, useLocation } from "react-router-dom";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import * as RouteConstant from "../constants/RouteConstants";

import Home from "../pages/HomePage/Home";
import TracklistModal from "../pages/Tracklist/TracklistModal";
import Album from "../pages/Album";
import NewReleases from "../pages/NewReleases";
import Page404 from "../pages/Page404";
import Dashboard from "../pages/Dashboard/Dashboard";
import Account from "../pages/Account";
import Favs from "../pages/Favs";
export default function Main() {
  const nodeRef = React.useRef(null);
  const location = useLocation();
  const background = location.state && location.state.background;
  useEffect(() => {
    // Anything in here is fired on component mount.

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <React.Fragment>
      <Routes location={background || location}>
        <Route index path={RouteConstant.HOME} element={<Home />} />
        <Route path={`${RouteConstant.PROGRAMS}/:id`} element={<Album />} />
        <Route path={RouteConstant.PROGRAMS} element={<NewReleases />} />
        <Route path={RouteConstant.DASHBOARD} element={<Dashboard />} />
        <Route path={RouteConstant.ACCOUNT} element={<Account />} />
        <Route path={RouteConstant.FAVORITES} element={<Favs />} />
        <Route path="*" element={<Page404 />}></Route>
      </Routes>
      {background && (
        <Routes>
          <Route
            path={`${RouteConstant.PROGRAMS}/:id`}
            element={
              <TransitionGroup>
                <CSSTransition
                  nodeRef={nodeRef}
                  in
                  timeout={600}
                  appear={true}
                  classNames="tracklist__overlay"
                  unmountOnExitx
                >
                  <TracklistModal>
                    <Album />
                  </TracklistModal>
                </CSSTransition>
              </TransitionGroup>
            }
          />
        </Routes>
      )}
    </React.Fragment>
  );
}
