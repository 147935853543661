import React, { Component } from "react";
import PropTypes from "prop-types";

export default class AuthorList extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.authors !== nextProps.authors;
  }

  render() {
    const { authors, handler } = this.props;
    return authors.map((author, index) => {
      return (
        <React.Fragment key={index}>
          <span onClick={handler}>{author.name}</span>

          {index + 1 !== authors.length && ", "}
        </React.Fragment>
      );
    });
  }
}

AuthorList.propTypes = {
  authors: PropTypes.array.isRequired,
  handler: PropTypes.func,
};
