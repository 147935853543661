import app from "./firebase";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  updateDoc,
  setDoc,
  doc,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { async } from "@firebase/util";

export async function getEpisodesByProgram(id) {
  const db = getFirestore(app);
  const episodes = await getDocs(
    query(collection(db, "episodes"), where("program", "==", id))
  );

  let aux = [];
  episodes.docs.map((mp, index) => {
    aux.push({
      authors: [
        {
          name: mp.data().authors,
          id: mp.data().id,
        },
      ],
      image: mp.data().image,
      track_number: "" + index + "",
      id: mp.data().id,
      name: mp.data().name,
      preview_url: mp.data().src,
      duration: mp.data().duration,
      uri: "spotify:track:" + mp.data().program,
      saved: false,
      desc: mp.data().desc,
      program: mp.data().program,
      src: mp.data().src,
      created_at: mp.data().created_at,
    });
  });

  return aux;
}

export async function addEpisode(
  name,
  desc,
  authors,
  image,
  duration,
  program,
  src
) {
  const db = getFirestore(app);
  const id = doc(collection(db, "episodes"));

  await setDoc(id, {
    name,
    desc,
    authors,
    image,
    duration,
    program,
    src,
    id: id.id,
    created_at: new Date().getFullYear(),
  });
  return true;
}

export async function removeEpisode(uid) {
  const db = getFirestore(app);
  deleteDoc(doc(db, "episodes", uid));
  return true;
}
