import React, { Component } from "react";
import PropTypes from "prop-types";

import Carousel from "../../components/Common/CarouselPrograms";
import { PROGRAMS } from "../../constants/RouteConstants";
import { connectNewReleases } from "../../containers/Album/NewReleasesContainer";
import { getPrograms } from "../../service/programs";

export class NewReleases extends Component {
  state = {
    programs: [],
  };
  handleChunkSize(chunkSize, list) {
    var R = [];
    for (var i = 0; i < list.length; i += chunkSize)
      R.push(list.slice(i, i + chunkSize));
    return R;
  }
  componentDidMount() {
    getPrograms().then((items) => {
      var itemsAux = [];

      this.handleChunkSize(3, items).forEach((subItems) => {
        subItems.forEach((item, index) => {
          if (index % 2) {
            itemsAux.push({
              id: "ads",
              title: "Ads",
              image: "",
              meta: 0,
              name: "Ads",
              schedule: "",
              totalTracks: 0,
            });
          }
          itemsAux.push(item);
        });
      });

      this.setState({
        programs: itemsAux,
      });
    });
  }

  render() {
    const { pending } = this.props.newReleases;
    const blockHeader = {
      title: "Programas",
      description: "Nuevo",
      link: PROGRAMS,
    };

    return (
      <Carousel
        pending={pending}
        items={this.state.programs}
        type="programs"
        blockHeader={blockHeader}
      />
    );
  }
}

NewReleases.propTypes = {
  newReleases: PropTypes.object.isRequired,
  loadNewReleases: PropTypes.func.isRequired,
};

export default connectNewReleases(NewReleases);
