import React, { useState } from "react";
import { toast } from "react-toastify";
import { logInWithEmailAndPassword } from "./../../service/auth";

import logoWKRadio from "../../images/logo.png";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = () => {
    if (!email) {
      toast.error("Debers ingresar tu correo electronico", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!password) {
      toast.error("Debers ingresar  una contraseña", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    logInWithEmailAndPassword(email, password).then((resp) => {
      if (!resp.user.email) {
        toast.error(
          "Ocurrio un error al crear tu cuenta, intentalo de nuevo más tarde.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    });
  };

  return (
    <>
      <h3 className="title has-text-black">Iniciar sesión</h3>
      <hr className="login-hr" />
      <p className="subtitle has-text-black">
        Para acceder a está funcionalidad, es necesario que inicies sesion.
      </p>
      <div className="card mt-3">
        <div className="card-content">
          <figure className="avatar">
            <img src={logoWKRadio} alt="sas" />
          </figure>
          <div className="mt-4">
            <div className="field">
              <div className="control">
                <input
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                  className="input is-large"
                  type="email"
                  placeholder="Correo electronico"
                  autoFocus={true}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input
                  value={password}
                  onChange={(ev) => setPassword(ev.target.value)}
                  className="input is-large"
                  type="password"
                  placeholder="Contraseña"
                />
              </div>
            </div>

            <button
              onClick={() => handleSignIn()}
              className="button is-block is-large is-fullwidth bgBtn"
            >
              ENTRAR <i className="fa fa-sign-in" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
