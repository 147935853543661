import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import PlayButton from "./Tracklist/TracklistPlayButton";
import Image from "./Tracklist/TracklistImage";
import Name from "./Tracklist/TracklistName";
import AuthorList from "../components/Common/AuthorList";
import Tracks from "../components/Common/Tracks";
import Page404 from "./Page404";

import { getProgramById, getEpisodesByProgram } from "../service/programs";

export default function Album() {
  let { id } = useParams();
  const [album, setAlbum] = useState({
    authors: [],
    date: "",
    error: false,
    id: "",
    image: "",
    isAvailableForPreview: false,
    isSaved: false,
    items: [],
    name: "",
    pending: false,
    totalTracks: 0,
  });
  const [tracks, setTracks] = useState([]);
  const source = { name: `album_${id}` };
  useEffect(() => {
    loadAlbumInfo(id);
    loadAlbumTracks(id);
    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  const loadAlbumInfo = (id) => {
    getProgramById(id).then((info) => {
      setAlbum(info);
    });
  };

  const loadAlbumTracks = (id) => {
    getEpisodesByProgram(id).then((ep) => {
      setTracks(ep);
    });
  };

  const addToMySavedAlbums = () => {
    //const  { addToMySavedAlbums, id } = this.props;
    //addToMySavedAlbums(id);
  };

  const removeFromMySavedAlbums = () => {
    // const { removeFromMySavedAlbums, id } = this.props;
    //removeFromMySavedAlbums(id);
  };

  return (
    <>
      {id !== null ? (
        <>
          <ToastContainer />
          <div
            className="tracklist__container custom-scrollbar"
            style={{ minHeight: "80vh" }}
          >
            <header className="tracklist__header">
              <Image image={album.image} />
              <div className="tracklist__info">
                <div className="tracklist__authors">
                  <AuthorList authors={album.authors} />
                </div>
                <Name name={album.name} />
                <ul className="tracklist__additional-info">
                  <li>{album.date}</li>
                  <li className="tracklist__dot"> • </li>
                  <li>{album.totalTracks}</li>
                </ul>
                <div className="tracklist__buttons">
                  <PlayButton
                    type="album"
                    id={id}
                    disabled={false}
                    trackList={tracks}
                  />
                </div>
              </div>
            </header>
            <div className="tracklist">
              <Tracks trackList={tracks} source={source} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="tracklist__loader flex-center">
            <Page404 />
          </div>
        </>
      )}
    </>
  );
}
