import React, { useState } from "react";
import { toast } from "react-toastify";
import { sendPasswordReset } from "./../../service/auth";
import logoWKRadio from "../../images/logo.png";

export default function PasswordReset() {
  const [email, setEmail] = useState("");

  return (
    <>
      <h3 className="title has-text-black">Restablecer contraseña</h3>
      <hr className="login-hr" />
      <p className="subtitle has-text-black">
        Si no puede acceder a su cuenta, pruebe restableciendo su contraseña con
        su correo electronico.
      </p>
      <div className="card mt-3">
        <div className="card-content">
          <figure className="avatar">
            <img src={logoWKRadio} alt="sas" />
          </figure>
          <div className="mt-4">
            <div className="field">
              <div className="control">
                <input
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                  className="input is-large"
                  type="email"
                  placeholder="Correo electronico"
                  autoFocus={true}
                />
              </div>
            </div>

            <button
              onClick={() => {
                sendPasswordReset(email);
                toast.success(
                  "Te enviamos un correo electronico con instrucciones para restablecer tu contraseña.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
                setEmail("");
              }}
              className="button is-block is-large is-fullwidth bgBtn"
            >
              ENVIAR INSTRUCCIONES{" "}
              <i className="fa fa-sign-in" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
