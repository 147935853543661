import React, { useEffect } from 'react';
import io from 'socket.io-client';
import Navbar from './components/Navbar';
import Player from './components/Player/Player';
import Main from './components/Main';
import './App.scss';

const ENDPOINT = 'https://chat.institutowilliams.edu.mx/';
var socket;

function App() {
  useEffect(() => {
    const textFromStorage = localStorage.getItem('isGG');
    if (!textFromStorage) {
      handleSocket();
    }
    return () => {
      if (socket !== undefined) {
        socket.close();
      }
    };
    // eslint-disable-next-line
  }, []);

  const handleSocket = () => {
    socket = io(ENDPOINT);
    const params = {
      username: generateUUID(),
      room: 'wmedia',
    };
    socket.emit('joinRoom', params, function (err) {
      if (err) {
        this.props.history.push('/');
      }
    });
  };

  const generateUUID = () => {
    var d = new Date().getTime(); //Timestamp
    var d2 =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  };

  return (
    <div className="app">
      <div className="app__container">
        <Navbar />
        <div className="app__content">
          <Main />
        </div>
        <Player />
      </div>
    </div>
  );
}

export default App;
