import React, { Component } from "react";
import PropTypes from "prop-types";

import Block from "../components/Common/Block";
import BlockHeader from "../components/Common/BlockHeader";
import InfiniteScroll from "../components/Common/InfiniteScroll";
import SkeletonBlocks from "../components/Skeleton/SkeletonBlocks";
import { connectNewReleases } from "../containers/Album/NewReleasesContainer";
import { getPrograms } from "../service/programs";

export class NewReleases extends Component {
  state = {
    programs: [],
  };

  componentDidMount() {
    getPrograms().then((items) => {
      this.setState({
        programs: items,
      });
    });
  }

  render() {
    const { pending, loadMorePending, total, items, error } =
      this.props.newReleases;
    const loadMore = () => this.props.loadMore(items.length);
    if (pending || error) {
      return <SkeletonBlocks />;
    }
    return (
      <section style={{ height: "80vh" }}>
        <BlockHeader title="Programas" />
        <InfiniteScroll
          total={total}
          dataLength={items.length}
          loadData={loadMore}
          pending={loadMorePending}
        >
          <div className="blocks-container" style={{ marginBottom: "100px" }}>
            {this.state.programs.map((item, index) => {
              return (
                <Block
                  key={item.id}
                  type="programs"
                  id={item.id}
                  meta={item.meta}
                  name={item.name}
                  image={item.image}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      </section>
    );
  }
}

NewReleases.propTypes = {
  newReleases: PropTypes.object.isRequired,
  loadNewReleases: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
};

export default connectNewReleases(NewReleases);
