import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "../../styles/Tracklist.scss";

export default function TracklistModal(props) {
  const navigate = useNavigate();
  useEffect(() => {
    // Anything in here is fired on component mount.
    if (!document.body.classList.contains("disable-scroll")) {
      document.body.classList.add("disable-scroll");
    }
    return () => {
      // Anything in here is fired on component unmount.
      document.body.classList.remove("disable-scroll");
    };
  }, []);

  const goBack = (e) => {
    if (e.target.closest(".tracklist__modal")) {
      return;
    }
    navigate(-1);
  };

  return (
    <div
      className="tracklist__overlay"
      onClick={(e) => {
        goBack(e);
      }}
    >
      <ToastContainer />
      <div className="tracklist__modal">{props.children}</div>
    </div>
  );
}
