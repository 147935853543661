import React, { useEffect, useState } from "react";
import { Heart } from "react-feather";
import { isFav, addFav, removeFav } from "../../service/favs";
import { auth } from "../../service/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";

export default function LikeButton(episode) {
  const [isActive, setIsActive] = useState(false);
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (episode.episode) {
      if (loading) {
        return;
      }

      if (user) {
        isFav(user.uid, episode.episode.id).then((resp) => {
          setIsActive(resp);
        });
      }
    } else {
      setIsActive(false);
    }
  }, []);

  const handleFavAction = () => {
    if (episode.episode.length > 0) {
      if (isActive) {
        removeFav(user.uid, episode.episode.id).then((resp) => {
          toast.success("Se elimino un item de tus favoritos correctamente.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActive(false);
        });
      } else {
        addFav(
          user.uid,
          episode.episode.authors,
          "2022",
          episode.episode.desc,
          episode.episode.duration,
          episode.episode.id,
          episode.episode.image,
          episode.episode.name,
          episode.episode.program,
          episode.episode.src
        ).then((resp) => {
          toast.success("Se agrego un item a tus favoritos correctamente.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActive(true);
        });
      }
    }
  };

  return (
    <button className="like-btn">
      <Heart
        onClick={() => handleFavAction()}
        className={`${isActive ? "like-btn_active" : ""}`}
      />
    </button>
  );
}
